import {Flex, Span} from './UI'
import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const Time = styled(Flex)`
    width: 124px;
    flex-direction: row;
    justify-content: space-between;
`

const TimeLabel = styled(Span)`
    font-size: 0.875rem;
    font-weight: 600;
`

const DayLabel = ({day, open, close}) =>
    <Flex justifyContent="space-between" flexDirection="row" width="100%" margin="0 0 12px 0">
        <Span>{day}</Span>
        <Time>
            {
                (!_.isEmpty(open) && !_.isEmpty(close)) ?
                    <>
                        <TimeLabel>{open}</TimeLabel>
                        <TimeLabel>-</TimeLabel>
                        <TimeLabel>{close}</TimeLabel>
                    </> :
                    <TimeLabel>Gesloten</TimeLabel>
            }

        </Time>
    </Flex>

export default DayLabel
