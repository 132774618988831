import React from 'react'
import styled from 'styled-components'
import location from '../images/location.jpg'
import alkmaar from '../images/visual-alkmaar.jpg'
import beverwijk from '../images/visual-beverwijk.jpg'
import castricum from '../images/castricum.jpg'
import Arrow from '../svg/arrow.svg'
import {Flex, Title, Title3, Span} from './UI'
import Button from './Button'
import DayLabel from './DayLabel'
import LocationVisual from './LocationVisual'
import { Link } from 'gatsby'
import _ from 'lodash'
import { breakpoints } from '../utils/helper'
import { OutboundLink } from 'gatsby-plugin-gtag'
import moment from 'moment'
import Toggle from './Toggle'

const Container = styled(Flex)`
    align-items: center;
    justify-content: space-between; 
    flex-direction: column;
    margin: ${props => props.padding ?? '0 0 80px 0'};
    background-color: ${props => props.theme.colors.white};
    
    @media ${breakpoints.tablet} {
        align-items: flex-start;
        flex-direction: row;
    }
`

const Subtitle = styled(Span)`
    color: ${props => props.color === 'red' ? props.theme.colors.red : props.theme.colors.black};
    white-space: pre-wrap;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    
    &.open {
        margin: 0 0 30px;
    }
    
    @media ${breakpoints.tablet} {
        font-size: 1.125rem;
    }
`

const StyledLink = styled(Link)`
    ${props => props.theme.fontStyle.h4};
    font-size: 1rem;
    margin: 30px 0 0 0;
    text-decoration: none;
    color: ${props => props.theme.colors.black};
    z-index: 100;
    
    svg {
        margin-right: 12px;
    }
    
    &:visited {
        color: ${props => props.theme.colors.black};
    }
    
    &:hover {
        color: ${props => props.theme.colors.red};
        
        #arrow {
            fill: ${props => props.theme.colors.red};
        }
    }
    
    &.mobile {
        display: block;
        @media ${breakpoints.tablet} {
            display: none;
        }
    }
    
    &.desktop {
        display: none;
        @media ${breakpoints.tablet} {
            display: block;
            
            padding: 0 30px;
        }
        
        @media ${breakpoints.large} {
            padding: 0 0;
        }
    }    
`

const ExternalLink = styled(OutboundLink)`
    text-decoration: none;
`

const LeftSection = styled(Flex)`
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    
    @media ${breakpoints.desktop} {
        width: ${props => props.large ? 'auto' : '50%'};
    }
`

const RightSection = styled(Flex)`
    flex-direction: column;
    width: 100%;
    padding: 24px 30px 0;
    
    // button {
    //     display: none;
    // }
    
    @media ${breakpoints.tablet} {
        padding: 0 30px 0 42px;
        
        button {
            display: block;
        }
    }
    
    @media ${breakpoints.desktop} {
        padding: 0 60px 0 120px;
        width: 50%;
    }
`

const Visual = styled.img`
    width: 100%;
    height: auto;
    
    @media ${breakpoints.desktop} {
        width: ${props => props.large ? '620px' : '100%'};
        height: ${props => props.large ? 'auto' : '100%'};
    }
`

const Note = styled.span` {
    font-size: 0.875rem;
    padding-bottom: 24px;
}
`


const LocationItem = ({id, data, open, link, home, largeVisual, address, padding}) => {
    const closeTime = () => {
        const now = moment()
        const day = _.get(data, `opening_hours.${moment().weekday() - 1}`)
        const start = moment(_.get(day, 'open'), 'HH:mm')
        const end = moment(_.get(day, 'close'), 'HH:mm')
        const isInBetween = now.isBetween(start, end)

        return isInBetween ? `nu open tot ${_.get(day, 'close')}` : 'nu gesloten'
    }

    const getVisual = () => {
        switch (_.lowerCase(_.get(data, 'city'))) {
            case 'alkmaar':
                return alkmaar
            case 'beverwijk':
                return beverwijk
            case 'castricum':
                return castricum
            default:
                return location
        }
    }

    return (
        <Container id={id} padding={padding}>
            <LeftSection large={largeVisual}>
                <LocationVisual>
                    <Visual src={getVisual()} alt={`${_.get(data, 'city')} afbeelding`} large={largeVisual}/>
                    <div>
                        <Title color="#FFFFFF">{_.get(data, 'city')}</Title>
                        <ExternalLink rel="noopener" href={_.get(data, 'route')} target="_blank">
                            <Button color={Button.styles.WHITE} >Route</Button>
                        </ExternalLink>
                    </div>
                </LocationVisual>
                {
                    link &&
                    <StyledLink to={`/vestigingen/${_.toLower(_.get(data, 'city'))}`} className="desktop">
                        <Arrow/> {`Bekijk diensten in ${_.get(data, 'city')}`}
                    </StyledLink>
                }
            </LeftSection>
            <RightSection>
                {
                    (address && _.get(data, 'address')) &&
                    <Flex alignItems="flex-start" flexDirection="row" width="100%" margin="0 0 30px 0" justifyContent="space-between">
                        <Subtitle color={'black'}>{_.get(data, 'address')}</Subtitle>
                        <ExternalLink rel="noopener" href={_.get(data, 'route')} target="_blank">
                            <Button color={Button.styles.BLACK}>Route</Button>
                        </ExternalLink>
                    </Flex>
                }
                {
                    !home && <Title3 margin="0 0 24px">Openingstijden</Title3>
                }
                {/*{*/}
                {/*    open && <Subtitle className="open" color="red">{closeTime()}</Subtitle>*/}
                {/*}*/}
                {
                    data?.city === "Alkmaar" && <Note>De interieurbaan is dagelijks geopend van <strong>10:00 tot 12:00</strong> en van <strong>12:30 tot 17:00 uur.</strong></Note>
                }
                {
                    !_.isUndefined(data) &&
                        _.get(data, 'opening_hours').map((item, i) =>
                            <DayLabel key={i} day={_.get(item, 'day')} open={_.get(item, 'open')} close={_.get(item, 'close')}/>)
                }
                {
                    _.get(data, 'opening_hours') && <Toggle label="Speciale openingstijden" data={_.get(data, 'special_days')}/>
                }
                {
                    link &&
                    <StyledLink to={`/vestigingen/${_.toLower(data.city)}`} className="mobile">
                        <Arrow/> {`Bekijk diensten in ${data.city}`}
                    </StyledLink>
                }
            </RightSection>
        </Container>
    )
}

export default LocationItem
