import styled from 'styled-components'
import Chevron from '../svg/red_chevron.svg'
import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import DayLabel from './DayLabel'
import {gsap, Power3} from "gsap";

const AccordionToggle = styled.div`
    font-size: 1.125;
    color: ${props => props.theme.colors.red};
    outline: none;
    ${props => props.theme.fontStyle.h4};
    cursor: pointer;
    padding: 0;
    margin: 22px 0 24px;
    
    svg {
        margin: 0px 0 0 18px;
        transform: ${props => props.open ? 'rotate(0)' : 'rotate(180deg)'}};
        transform-origin: center;
        transition: .25s ease-in-out;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: ${props => props.open ? '100px' : '400px'};
    transition: .5s ease-out;
`

const Days = styled.div`
    width: 100%;
`

const Toggle = ({label, data}) => {
    const [open, setOpen] = useState(true)
    const container = useRef(null)

    useEffect(() => {
        gsap.to(container.current, {delay: open ? 0 : 0.25, duration: open ? 0.2 : 0.3, opacity: open ? 0 : 1, ease: Power3.easeInOut})
    }, [open])

    const handleToggle = () => {
        setOpen(!open)
    }

    return (
        <Container open={open}>
            <AccordionToggle onClick={handleToggle} open={open}>{label}<Chevron/></AccordionToggle>
            <Days ref={container}>
                {

                    data.map((item, i) =>
                        <DayLabel key={i} day={_.get(item, 'day')} open={_.get(item, 'open')} close={_.get(item, 'close')}/>)

                }
            </Days>
        </Container>
    )
}

export default Toggle
