import styled from "styled-components";
import {Flex} from "./UI";
import {breakpoints} from "../utils/helper";

const LocationVisual = styled(Flex)`
    position: relative;
    align-items: center; 
    justify-content: center;
    
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        height: 100px;
        
        button {
            display: block;
        }
        
        @media ${breakpoints.tablet} {
            button {
                display: none;
            }
        }
    }
`

export default LocationVisual
